<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ $t("product.title") }}</h1>

    <div v-show="showWizard">
      <product-wizard :initial-product="initialProduct" :production-units="productionUnits" />
    </div>
  </article>
</template>

<script>
import ProductWizard from "../components/product/ProductWizard";
import ProductService from "@/components/product/ProductService";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import { mapActions, mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import DemoService from "@/components/DemoService";

export default {
  components: {
    ProductWizard,
  },
  data() {
    return {
      initialProduct: null,
      productionUnits: [],
      showWizard: false,
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactoryId"]),
    ...mapGetters("user", ["email"]),
    factoryId() {
      return this.activeFactoryId;
    },
    isFactoryIdDefined() {
      return this.factoryId != null && this.factoryId !== "";
    },
  },
  watch: {
    factoryId() {
      this.fetchProductionUnits(null);
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    async fetchProduct(productId) {
      const includeDeleted = false;
      ProductService.getProduct(productId, includeDeleted)
        .then((response) => this.handleProductRetrievalResponse(response, productId))
        .catch((error) => this.handleProductRetrievalError(error.response));
    },
    handleProductRetrievalResponse(httpResponse, productId) {
      if (httpResponse.status === 200) {
        let product = httpResponse.data;
        product.description = DemoService.maskProductNameIfNecessary(this.email, product.description);
        if (product) {
          this.fetchProductionUnits(product);
        } else {
          this.showOperationError(this.$t("product.errors.noProductFound", { productId: productId }));
          this.$router.push(RouteService.toProducts());
        }
      }
    },
    handleProductRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.$router.push(RouteService.toProducts());
    },
    async fetchProductionUnits(product) {
      ProductionUnitService.getProductionUnits(this.factoryId)
        .then((response) => this.handleProductionUnitsRetrievalResponse(response, product))
        .catch((error) => this.handleProductionUnitsRetrievalError(error.response));
    },
    handleProductionUnitsRetrievalResponse(httpResponse, product) {
      if (httpResponse.status === 200) {
        this.productionUnits = httpResponse.data;
        this.productionUnits.forEach((x, index) => x.name = DemoService.maskProductionUnitNameIfNecessary(this.email, x.name, index + 1));
        this.initialProduct = product;
        this.showWizard = true;
      }
    },
    handleProductionUnitsRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
  },
  async mounted() {
    const productId = this.$route.params.id;
    if (productId) {
      await this.fetchProduct(this.$route.params.id);
    } else {
      if (this.isFactoryIdDefined) {
        await this.fetchProductionUnits(null);
      }
    }
  },
};
</script>
