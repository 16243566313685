<template>
  <fragment>
    <wx-form-container class="wizard">
      <v-form ref="productForm" lazy-validation>
        <fieldset>
          <legend class="legend-header top-legend">{{ $t("common.wizardItemDetails") }}</legend>
          <v-row>
            <v-col cols="12">
              <!-- - - - - - - - - - - - -     Sku     - - - - - - - - - - - - - - - -->
              <wx-text-field
                v-model.trim="sku"
                :label="$t('product.details.sku') + ' *'"
                :rules="[() => validateSku()]"
                :counter="skuMaxLength"
                :maxlength="skuMaxLength"
                :disabled="!isCreation"
              />
            </v-col>
            <v-col cols="12">
              <!-- - - - - - - - - - - -       Name       - - - - - - - - - - - - - - -->
              <wx-text-field
                v-model.trim="name"
                :rules="[() => validateName()]"
                :label="$t('common.name')"
                :counter="productNameMaxLength"
                :maxlength="productNameMaxLength"
              />
            </v-col>
            <v-col cols="12">
              <!-- - - - - - - - - - - -     Category     - - - - - - - - - - - - - - -->
              <wx-text-field
                v-model.trim="category"
                :rules="[() => validateCategory()]"
                :label="$t('product.wizard.category')"
                :counter="productCategoryMaxLength"
                :maxlength="productCategoryMaxLength"
              />
            </v-col>
          </v-row>
        </fieldset>

        <section class="product-target-row">
          <fieldset class="inline-fields">
            <legend>
              {{ $t("product.wizard.targetSubSectionTitle") }}
              <wx-contextualized-help :tooltip-text="$t('product.wizard.targetSubSectionTooltip')" />
            </legend>
            <v-row>
              <v-col class="field-col" cols="6">
                <!-- - - - - - - - - - - -       Target Unit       - - - - - - - - - - - - - - -->
                <wx-autocomplete
                  v-model="targetUnit"
                  :items="availableTargetUnits"
                  :label="$t('product.details.targetUnit')"
                  :rules="[() => validateTargetUnit()]"
                  @change="onTargetUnitChange"
                  item-text="name"
                  item-value="id"
                  validate-on-blur
                >
                </wx-autocomplete>
              </v-col>
              <v-col class="field-col" cols="6">
                <!-- - - - - - - - - - - - -     Target Value     - - - - - - - - - - - - - - - -->
                <wx-text-field
                  ref="targetValue"
                  v-model.number="targetValue"
                  min="0"
                  max="99999"
                  :label="$t('product.details.targetValue')"
                  :suffix="targetUnit"
                  type="number"
                  :rules="[() => validateTargetValue()]"
                  @change="revalidateTargetFields"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="field-col" cols="6">
                <!-- - - - - - - - - - - - -     Lower Tolerance Value     - - - - - - - - - - - - - - - -->
                <wx-text-field
                  ref="lowerTolerance"
                  v-model.number="targetLowerTolerance"
                  min="0"
                  max="99999"
                  :label="$t('product.details.targetLowerTolerance')"
                  :suffix="targetUnit"
                  type="number"
                  :rules="[() => validateTargetLowerTolerance()]"
                  :disabled="!targetValue || !targetUnit"
                  @blur="revalidateTargetFields"
                />
              </v-col>
              <v-col class="field-col" cols="6">
                <!-- - - - - - - - - - - - -     Upper Tolerance Value     - - - - - - - - - - - - - - - -->
                <wx-text-field
                  ref="upperTolerance"
                  v-model.number="targetUpperTolerance"
                  min="0"
                  max="99999"
                  :label="$t('product.details.targetUpperTolerance')"
                  :suffix="targetUnit"
                  type="number"
                  :rules="[() => validateTargetUpperTolerance()]"
                  :disabled="!targetValue || !targetUnit"
                  @blur="revalidateTargetFields"
                />
              </v-col>
            </v-row>
          </fieldset>
        </section>

        <!-- create the production units associated to the current the product -->
        <ul class="associated-pu-list">
          <li
            class="pu-item"
            v-for="(associatedPU, index) in associatedProductionUnits"
            :key="'production-unit' + index"
          >
            <v-row>
              <v-col cols="12">
                <!-- - - - - - - - -     Production Unit Name     - - - - - - - - - - -  -->
                <header class="legend-header">
                  <h3 class="wx-typo-h2">{{ $t("productionUnit.titleEdition") }} {{ index + 1 }}</h3>
                  <v-chip class="ma-2" color="secondary" x-small v-if="isProductionUnitSelected(associatedPU)">{{
                    getPackages(associatedPU)
                  }}</v-chip>
                  <wx-btn-standard
                    class="btn-delete"
                    text
                    @click="deleteLine(index)"
                    :disabled="isProductAssociatedToOneProductionUnit()"
                    :title="$t('product.wizard.deletion.buttonRemoveLineHoverTitle')"
                  >
                    <v-icon left>mdi-close</v-icon>
                    {{ $t("product.wizard.deletion.buttonRemove") }}
                  </wx-btn-standard>
                </header>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <wx-select
                  v-model="associatedPU.productionUnit"
                  class="huge-field-width single-field-row"
                  :value="associatedPU.productionUnit.id"
                  :items="currentProductionUnits"
                  item-text="name"
                  item-value="id"
                  :label="$t('productionUnit.titleEdition') + ' *'"
                  :rules="[
                    () => isProductionUnitMissing(associatedPU.productionUnit.id),
                    isSelectedProductionUnitDuplicated(associatedPU.productionUnit.id),
                  ]"
                  @change="(selectedProductionUnit) => onSelectedProductionUnit(selectedProductionUnit, index)"
                  return-object
                />
              </v-col>
            </v-row>

            <section class="convert-factor-objective-container">
              <!-- - - - - - - - -     Conversion Factor     - - - - - - - - - - -  -->
              <fieldset class="inline-fields">
                <legend>
                  {{ $t("product.details.conversionFactor") }}
                  <wx-contextualized-help :tooltip-text="$t('product.wizard.conversionFactorSubSectionTooltip')" />
                </legend>
                <v-row>
                  <v-col class="field-col" cols="6">
                    <wx-text-field
                      v-model.number="associatedPU.conversionFactorCount"
                      :label="$t('product.details.conversionFactorCapturedQuantity') + ' *'"
                      :suffix="$t('product.details.conversionFactorCount')"
                      type="number"
                      :rules="[() => validateConversionFactorCount(associatedPU.conversionFactorCount)]"
                    />
                  </v-col>
                  <v-col class="field-col" cols="6">
                    <!-- TODO: the product conversion factor unity should be determined later. -->
                    <wx-text-field
                      v-model.number="associatedPU.conversionFactorQuantity"
                      class="equal-before"
                      :label="$t('product.details.conversionFactorRealQuantity') + ' *'"
                      :suffix="getConvertedUnitName(associatedPU)"
                      type="number"
                      :rules="[() => validateConversionFactorRealQuantity(associatedPU.conversionFactorQuantity)]"
                    />
                  </v-col>
                  <v-col class="field-col" cols="6">
                    <!-- - - - - - - -    Product Objective per PU    - - - - - - - - -  -->
                    <ul class="objectives-list">
                      <li v-for="objective in associatedPU.objectives" :key="objective.objectiveType">
                        <wx-text-field
                          v-if="
                            isRateObjective(objective.objectiveType) &&
                              isRateObjectiveAuthorized(associatedPU.productionUnit.id)
                          "
                          v-model="objective.objectiveValue"
                          type="text"
                          :label="$t('product.wizard.update.targetRatePerHour')"
                          :suffix="getRateObjectiveUnitName(associatedPU)"
                          :rules="[() => validateRateObjectiveValue(objective.objectiveValue)]"
                        />
                        <wx-text-field
                          v-if="
                            isGiveawayObjective(objective.objectiveType) &&
                              isGiveawayObjectiveAuthorized(associatedPU.productionUnit.id)
                          "
                          v-model.number="objective.objectiveValue"
                          type="number"
                          min="0"
                          max="99999"
                          :label="$t('product.wizard.update.targetGiveawayPercentage')"
                          suffix="%"
                          :rules="[() => validateGiveawayObjectiveValue(objective.objectiveValue)]"
                        />
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </fieldset>
            </section>
          </li>
        </ul>
        <!-- - - - - - - - -       Production Units       - - - - - - - - - - - -->
        <footer class="bottom-add-row d-flex flex-row-reverse">
          <wx-btn-standard text @click="addNewLine" :title="$t('product.wizard.creation.buttonAddNewLineHoverTitle')">
            <v-icon left>mdi-plus</v-icon>
            {{ $t("product.wizard.creation.buttonAddNewLine") }}
          </wx-btn-standard>
        </footer>

        <form-footer-actions :click-submit="submit" cancel-to="toProducts" />
      </v-form>
    </wx-form-container>
  </fragment>
</template>

<script>
import WxFormContainer from "@/components/ui/WxFormContainer";
import FormFooterActions from "@/components/ui/FormFooterActions";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxTextField from "@/components/ui/WxTextField";
import WxSelect from "@/components/ui/WxSelect";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import ProductService from "@/components/product/ProductService";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import Validations from "@/components/validations";
import { mapActions, mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import PackageFeatures from "@/components/PackageFeatures";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp";
import ProductObjectives from "@/components/product/ProductObjectives";
import { compareProductionUnitsByName } from "@/components/SortUtils";

export default {
  name: "ProductWizard",
  components: {
    WxFormContainer,
    FormFooterActions,
    WxBtnStandard,
    WxSelect,
    WxTextField,
    WxAutocomplete,
    WxContextualizedHelp,
  },
  props: {
    initialProduct: {
      type: Object,
      default: null,
    },
    productionUnits: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sku: null,
      name: null,
      category: null,
      isCreation: true,
      targetValue: null,
      targetUnit: null,
      targetLowerTolerance: null,
      targetUpperTolerance: null,
      associatedProductionUnits: [
        {
          associationId: null,
          productionUnit: {},
          conversionFactorCount: 1,
          conversionFactorQuantity: 1,
          objectives: [
            {
              objectiveType: ProductObjectives.rateObjectiveName,
              objectiveValue: 1,
            },
            {
              objectiveType: ProductObjectives.giveawayObjectiveName,
              objectiveValue: null,
            },
          ],
        },
      ],
      skuError: false,
      skuErrorMessages: [],
      skuMaxLength: Validations.productSkuMaxLength,
      productNameMaxLength: Validations.productNameMaxLength,
      productCategoryMaxLength: Validations.productCategoryMaxLength,
      conversionFactorMaxLength: Validations.productConversionFactorMaxLength,
      productObjectiveMaxLength: Validations.productObjectiveMaxLength,
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactoryId"]),
    ...mapGetters("packages", ["puHasRequiredFeature"]),
    currentProductionUnits() {
      let puList = [...this.productionUnits];
      puList.sort(compareProductionUnitsByName);
      return puList;
    },
    availableTargetUnits() {
      let allOptions = [{ id: "-", name: this.$t("common.none") }];
      let productAvailableTargetUnits = Validations.getProductAvailableTargetUnits();
      allOptions.push(...productAvailableTargetUnits);
      return allOptions;
    },
  },
  watch: {
    initialProduct() {
      if (this.initialProduct) {
        this.isCreation = false;
        this.sku = this.initialProduct.sku;
        this.skuMaxLength = undefined;
        this.category = this.initialProduct.category;
        this.targetValue = this.initialProduct.target?.value;
        this.targetUnit = this.initialProduct.target?.unit;
        this.targetLowerTolerance = this.initialProduct.target?.tolerance?.lower_value;
        this.targetUpperTolerance = this.initialProduct.target?.tolerance?.upper_value;
        this.name = this.initialProduct.description;
        this.setAssociatedProductionUnits(this.initialProduct.associated_production_units);
      }
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationSuccess", "showOperationError", "showOperationWarning"]),
    getRouteBack() {
      return RouteService.toProducts();
    },
    isProductionUnitSelected(associatedPU) {
      return associatedPU?.productionUnit?.id;
    },
    getPackages(associatedPU) {
      let puPackages = associatedPU?.productionUnit?.packages?.filter((p) => String(p))?.sort();
      return puPackages?.at(-1) ?? null;
    },
    validateSku() {
      // Sku edition is disabled this.isCreation
      if (this.isCreation) {
        if (!this.sku) {
          return this.$t("product.errors.noSku");
        }
        if (this.sku.trim().length > this.skuMaxLength) {
          return this.$t("product.errors.skuTooLong", {
            maxLength: this.skuMaxLength,
          });
        }
      }
      this.skuError = false;
      this.skuErrorMessages = [];
      return true;
    },
    validateName() {
      if (!!this.name && this.name.trim().length > this.productNameMaxLength) {
        return this.$t("product.errors.nameTooLong", {
          maxLength: this.productNameMaxLength,
        });
      }
      return true;
    },
    validateCategory() {
      if (!!this.category && this.category.trim().length > this.productCategoryMaxLength) {
        return this.$t("product.errors.categoryTooLong", {
          maxLength: this.productCategoryMaxLength,
        });
      }
      return true;
    },
    isSelectedTargetUnitSetToNone() {
      return this.targetUnit === "-";
    },
    onTargetUnitChange() {
      if (this.isSelectedTargetUnitSetToNone()) {
        this.targetValue = null;
        this.targetLowerTolerance = null;
        this.targetUpperTolerance = null;
      }
      this.validateUnitChangeWarning();
      this.revalidateTargetFields();
    },
    validateUnitChangeWarning() {
      if (this.targetValue) this.showOperationWarning(this.$t("product.wizard.unitChangeWarningText"));
    },
    revalidateTargetFields() {
      this.$refs.targetValue.validate(true);
      this.$refs.lowerTolerance.validate(true);
      this.$refs.upperTolerance.validate(true);
    },
    validateTargetUnit() {
      if (this.isSelectedTargetUnitSetToNone()) return true;

      if (this.targetValue && !this.targetUnit) {
        return this.$t("product.errors.noTargetUnit");
      }

      if (this.targetUnit && !Validations.isProductTargetUnitValid(this.targetUnit)) {
        const supportedValues = this.availableTargetUnits.map((unit) => unit.name).join();
        return this.$t("product.errors.invalidTargetUnit", { supportedValues: supportedValues });
      }
      return true;
    },
    validateTargetValue() {
      if (this.isSelectedTargetUnitSetToNone()) {
        if (this.targetValue != null && this.targetValue > 0) {
          return this.$t("product.errors.unsupportedTargetValueForUnitNone");
        }
        return true;
      }

      if (this.targetUnit && this.targetValue == null) {
        return this.$t("product.errors.noTargetValue");
      }

      if (this.targetValue != null && this.targetValue == 0) {
        return this.$t("product.errors.invalidTargetValue");
      }

      if (this.targetValue && !Validations.isProductTargetValueValid(this.targetValue)) {
        return this.$t("product.errors.invalidTargetValue");
      }
      return true;
    },
    validateTargetLowerTolerance() {
      if (this.isSelectedTargetUnitSetToNone()) {
        if (this.targetLowerTolerance != null && this.targetLowerTolerance > 0) {
          return this.$t("product.errors.unsupportedTargetLowerToleranceForUnitNone");
        }
        return true;
      }

      if (this.targetUpperTolerance && !this.targetLowerTolerance) {
        return this.$t("product.errors.noTargetLowerToleranceValue");
      }

      if (this.targetLowerTolerance && !Validations.isProductTargetValueValid(this.targetLowerTolerance)) {
        return this.$t("product.errors.invalidTargetValue");
      }

      if (this.targetLowerTolerance > this.targetValue) {
        return this.$t("product.errors.invalidTargetLowerToleranceValue");
      }
      return true;
    },
    validateTargetUpperTolerance() {
      if (this.isSelectedTargetUnitSetToNone()) {
        if (this.targetUpperTolerance != null && this.targetUpperTolerance > 0) {
          return this.$t("product.errors.unsupportedTargetUpperToleranceForUnitNone");
        }
        return true;
      }

      if (this.targetLowerTolerance && !this.targetUpperTolerance) {
        return this.$t("product.errors.noTargetUpperToleranceValue");
      }

      if (this.targetUpperTolerance && !Validations.isProductTargetValueValid(this.targetUpperTolerance)) {
        return this.$t("product.errors.invalidTargetValue");
      }

      if (this.targetUpperTolerance < this.targetValue && this.targetUpperTolerance) {
        return this.$t("product.errors.invalidTargetUpperToleranceValue");
      }
      return true;
    },
    validateConversionFactorCount(conversionFactorCount) {
      if (
        isNaN(conversionFactorCount) ||
        conversionFactorCount <= 0 ||
        conversionFactorCount > 9999999999 ||
        !Validations.isProductConversionFactorValid(conversionFactorCount)
      ) {
        return this.$t("product.errors.invalidConversionFactorCapturedQuantity");
      }
      return true;
    },
    validateConversionFactorRealQuantity(conversionFactorQuantity) {
      if (
        isNaN(conversionFactorQuantity) ||
        conversionFactorQuantity <= 0 ||
        conversionFactorQuantity > 9999999999 ||
        !Validations.isProductConversionFactorValid(conversionFactorQuantity)
      ) {
        return this.$t("product.errors.invalidConversionFactorRealQuantity");
      }
      return true;
    },
    createConversionFactor(associatedProductionUnit) {
      return associatedProductionUnit.conversionFactorCount + ":" + associatedProductionUnit.conversionFactorQuantity;
    },
    validateRateObjectiveValue(objectiveValue) {
      let parsedObjectiveValue = parseFloat(objectiveValue);
      if (
        isNaN(parsedObjectiveValue) ||
        parsedObjectiveValue <= 0 ||
        !Validations.isProductRateObjectiveValueValid(objectiveValue)
      ) {
        return this.$t("product.errors.invalidRateObjective");
      }
      return true;
    },
    validateGiveawayObjectiveValue(objectiveValue) {
      if (
        isNaN(objectiveValue) ||
        objectiveValue <= 0 ||
        objectiveValue > 9999999999 ||
        !Validations.isProductGiveawayObjectiveValueValid(objectiveValue)
      ) {
        return this.$t("product.errors.invalidGiveawayObjective");
      }

      return true;
    },
    // validate the associatedProductionUnit
    submit() {
      if (this.isCreation) {
        this.create();
      } else {
        this.update();
      }
    },
    create() {
      const isValid = this.$refs.productForm.validate();
      if (isValid) {
        const associatedProductionUnits = this.createAssociatedProductionUnits();
        const target = this.createProductTarget();
        const newProduct = {
          sku: this.sku,
          category: this.category,
          description: this.name,
          target: target,
          associated_production_units: associatedProductionUnits,
        };
        ProductService.createProduct(this.activeFactoryId, newProduct)
          .then((response) => this.handleProductCreationResponse(response, newProduct))
          .catch((error) => this.handleProductCreationError(error.response));
      }
    },
    update() {
      const isValid = this.$refs.productForm.validate();
      if (isValid) {
        let associatedProductionUnits = this.createAssociatedProductionUnits();
        const target = this.createProductTarget();
        const updatedProduct = {
          id: this.initialProduct.id,
          category: this.category,
          description: this.name,
          target: target,
          associated_production_units: associatedProductionUnits,
        };
        ProductService.updateProduct(updatedProduct)
          .then((response) => this.handleProductUpdateResponse(response))
          .catch((error) => this.handleProductUpdateError(error.response));
      }
    },
    createProductTarget() {
      let target = null;
      if (!this.isSelectedTargetUnitSetToNone()) {
        let targetTolerance = null;
        if (
          this.targetLowerTolerance != null &&
          this.targetLowerTolerance > 0 &&
          this.targetUpperTolerance != null &&
          this.targetUpperTolerance > 0
        ) {
          targetTolerance = {
            lower_value: this.targetLowerTolerance,
            upper_value: this.targetUpperTolerance,
          };
        }
        if (this.targetValue != null && this.targetUnit != null) {
          target = {
            value: this.targetValue,
            unit: this.targetUnit,
            tolerance: targetTolerance,
          };
        }
      }
      return target;
    },
    createAssociatedProductionUnits() {
      return this.associatedProductionUnits.map((associatedPU) => ({
        association_id: associatedPU.associationId,
        production_unit_id: associatedPU.productionUnit.id,
        conversion_factor: this.createConversionFactor(associatedPU),
        objectives: this.buildProductionUnitObjectives(associatedPU),
      }));
    },
    buildProductionUnitObjectives(associatedProductionUnit) {
      return associatedProductionUnit.objectives
        .filter((objective) => objective.objectiveValue)
        .map((objective) => ({
          objective_type: objective.objectiveType,
          value: objective.objectiveValue,
        }));
    },
    setAssociatedProductionUnits(associatedProductionUnits) {
      this.associatedProductionUnits.splice(0, 1);
      this.associatedProductionUnits = associatedProductionUnits.map((associatedPU) => ({
        associationId: associatedPU.association_id,
        productionUnit: this.getProductionUnitById(associatedPU.production_unit_id),
        conversionFactorCount: Number(associatedPU.conversion_factor.split(":", 2)[0]).toLocaleString("fullwide", {
          useGrouping: false,
          maximumFractionDigits: 8,
        }),
        conversionFactorQuantity: Number(associatedPU.conversion_factor.split(":", 2)[1]).toLocaleString("fullwide", {
          useGrouping: false,
          maximumFractionDigits: 8,
        }),

        objectives: this.getObjectives(associatedPU.objectives),
      }));
    },
    getProductionUnitById(productionUnitId) {
      return this.productionUnits.find((pu) => pu.id === productionUnitId);
    },
    getConvertedUnitName(associatedPU) {
      if (associatedPU && associatedPU.productionUnit && associatedPU.productionUnit.converted_unit_name) {
        return ProductionUnitService.getUnitName(associatedPU.productionUnit.converted_unit_name);
      }
      return ProductionUnitService.getUnitName(null); // used to get the default one.
    },
    getRateObjectiveUnitName(associatedPU) {
      return this.getConvertedUnitName(associatedPU) + "/h";
    },
    isRateObjective(objectiveType) {
      return objectiveType === ProductObjectives.rateObjectiveName;
    },
    isGiveawayObjective(objectiveType) {
      return objectiveType === ProductObjectives.giveawayObjectiveName;
    },
    getObjectives(associatedPUObjectives) {
      this.createEmptyRateObjectiveIfNecessary(associatedPUObjectives);
      this.createEmptyGiveawayObjectiveIfNecessary(associatedPUObjectives);

      return associatedPUObjectives.map((objective) => ({
        objectiveType: objective.objective_type,
        objectiveValue: objective.value,
      }));
    },
    createEmptyRateObjectiveIfNecessary(associatedPUObjectives) {
      if (!associatedPUObjectives.some((obj) => obj.objective_type === ProductObjectives.rateObjectiveName)) {
        associatedPUObjectives.push({
          objective_type: ProductObjectives.rateObjectiveName,
          value: null,
        });
      }
    },
    createEmptyGiveawayObjectiveIfNecessary(associatedPUObjectives) {
      if (!associatedPUObjectives.some((obj) => obj.objective_type === ProductObjectives.giveawayObjectiveName)) {
        associatedPUObjectives.push({
          objective_type: ProductObjectives.giveawayObjectiveName,
          value: null,
        });
      }
    },
    isRateObjectiveAuthorized(productionUnitId) {
      return this.puHasRequiredFeature(productionUnitId, PackageFeatures.performance);
    },
    isGiveawayObjectiveAuthorized(productionUnitId) {
      return this.puHasRequiredFeature(productionUnitId, PackageFeatures.giveaway);
    },
    handleProductCreationResponse(httpResponse, newProduct) {
      if (httpResponse.status === 201) {
        this.showOperationSuccess(this.$t("product.successfullyCreated", { sku: newProduct.sku }));
        this.close();
      }
    },
    handleProductUpdateResponse(httpResponse) {
      if (httpResponse.status === 200) {
        this.showOperationSuccess(this.$t("product.successfullyUpdated", { sku: this.sku }));
        this.close();
      }
    },
    handleProductCreationError(httpResponse) {
      const errorMessage = ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage);
      if (httpResponse.status === 409) {
        this.skuErrorMessages = errorMessage;
        this.skuError = true;
      } else {
        this.showOperationError(errorMessage);
      }
    },
    handleProductUpdateError(httpResponse) {
      const errorMessage = ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage);
      this.showOperationError(errorMessage);
    },
    getErrorMessage(code, args, message) {
      switch (code) {
        case "CS_PROD_POST_20001":
          return this.$t("product.errors.duplicateSku");
        case "DSH_PROD_PUT_10002": {
          const regex = /sku '(.*)' is currently selected on Production Unit '(.*)';/gm;
          let matches = regex.exec(message);
          let sku = !!matches && matches.length >= 3 ? matches[1] : "";
          let puName = !!matches && matches.length >= 3 ? matches[2] : "";
          return this.$t("product.errors.productInSkuPreventingAssociationDeletion", { sku: sku, puName: puName });
        }
      }
      if (code === "CS_PROD_POST_20001") return this.$t("product.errors.duplicateSku");
      else return this.$t("common.errors.default", { code: code });
    },
    addNewLine() {
      this.createEmptyAssociatedProductionUnit();
    },
    createEmptyAssociatedProductionUnit() {
      this.associatedProductionUnits.push({
        associationId: null,
        productionUnit: {},
        conversionFactorCount: 1,
        conversionFactorQuantity: 1,
        objectives: [
          {
            objectiveType: ProductObjectives.rateObjectiveName,
            objectiveValue: 1,
          },
          {
            objectiveType: ProductObjectives.giveawayObjectiveName,
            objectiveValue: null,
          },
        ],
      });
    },
    isProductAssociatedToOneProductionUnit() {
      // The product will be associated at least to one production unit.
      return this.associatedProductionUnits.length === 1;
    },
    deleteLine(index) {
      this.associatedProductionUnits.splice(index, 1);
    },
    onSelectedProductionUnit(selectedProductionUnit, associatedPUIndex) {
      let associatedPU = this.associatedProductionUnits[associatedPUIndex];
      associatedPU.productionUnit = selectedProductionUnit;
    },
    isProductionUnitMissing(selectedProductionUnitId) {
      if (!selectedProductionUnitId) return this.$t("product.errors.missingProductionUnit");
      return true;
    },
    isSelectedProductionUnitDuplicated(selectedProductionUnitId) {
      let productionUnitDuplicatedCount = this.associatedProductionUnits
        .map((associatedPU) => associatedPU?.productionUnit?.id)
        .filter((productionUnitId) => {
          return productionUnitId === selectedProductionUnitId;
        }).length;
      if (productionUnitDuplicatedCount > 1) return this.$t("product.errors.duplicatedProductionUnit");
      return true;
    },
    close() {
      this.sku = null;
      this.name = null;
      this.category = null;
      this.isCreation = true;
      this.targetValue = null;
      this.targetUnit = null;
      this.targetLowerTolerance = null;
      this.targetUpperTolerance = null;
      this.associatedProductionUnits = [];
      this.$refs.productForm.resetValidation();
      this.$router.push(this.getRouteBack());
    },
  },
};
</script>

<style lang="scss" scoped>
.product-target-row,
.convert-factor-objective-container {
  .inline-fields {
    width: 100%;
  }
}

.product-target-row {
  padding-bottom: var(--box-padding-admin);

  .inline-fields {
    flex-direction: column;
  }
}
.convert-factor-objective-container {
  display: flex;
  padding-bottom: var(--box-padding-admin);

  .objectives-list {
    flex-shrink: 0;
  }
}

.associated-pu-list {
  .pu-item {
    .legend-header {
      display: flex;

      .v-btn {
        margin-top: 0;
        margin-left: auto; // aligned to right
      }
    }
  }
}

// Unknowen containers. Could be obsolete (mDube, may 2022)
.product-tolerance-value {
  width: 45%;
}
</style>
